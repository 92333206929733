import React from "react"
import { Link } from "gatsby"
import Layout from "./../../components/layouts/Default"
import Seo from "./../../components/layouts/Seo"
import Header from "./../../components/common/Header"
import SvgFeature from "./../../components/common/SvgFeature"
import { graphql, useStaticQuery } from "gatsby"

import FeatureSection from "./../../components/containers/FeatureSection"
import ContContentTwoColumns from "./../../components/containers/ContContentTwoColumns"
import ContentTextbox from "./../../components/common/ContentTextbox"

import CtaSection from "./../../components/common/CtaSection"

import Euro from "./../../data/img/illustrations/euro.svg"
import Fonds from "./../../data/img/illustrations/fonds.svg"

import illustrationsInvestement from "./../../data/img/illustrations/investment2.svg"

export default function Page() {
  const data = useStaticQuery(graphql`
    query GeldanlageAllgemeineGeldanlage {
      image: file(
        relativePath: { eq: "headers/dierda_geldanlage-allgemein_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const anfrageLink = "/geldanlage/allgemeine-geldanlage/anfragen/"

  const svg = {
    invest: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19" />
        <polyline points="4 12 7 8 11 10 16 4 20 2" />
      </svg>
    ),
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <rect x="4" y="4" width="16" height="16" rx="2" />
        <path d="M9 12l2 2l4 -4" />
      </svg>
    ),
  }

  const content = {
    one: (
      <ContentTextbox title="Mit wenig Grundkapital Vermögen aufbauen">
        <p>
          Wusstest Du, dass Du auch mit geringem Grundkapital ein Vermögen
          aufbauen kannst? Das funktioniert auch ganz nach Deiner individuellen
          Lebenssituation. Entscheidend ist dabei der Zeithorizont, der dir
          dafür zur Verfügung steht, denn:
        </p>
        <p>
          <strong>Rendite x Zeit = Vermögen </strong>
        </p>
        <p>
          <strong>
            Je früher Du anfängst zu investieren, desto mehr Einfluss hat dies
            auf Deine Vermögenslage
          </strong>
          . Hier spielt der machtvolle, oftmals unterschätzte Zinseszinseffekt
          Dir in die Hände. Anbei eine Beispielrechnung. Du kannst gerne die
          Zahlen auf Deine Situation anpassen.
        </p>
        <img
          src={illustrationsInvestement}
          alt="Grafik über ein Investement"
          className="w-9/12 mx-auto"
        />
      </ContentTextbox>
    ),
    two: (
      <ContentTextbox title="Die aktuelle Situation">
        <p>
          Mit dem Sparbuch oder Festgeldanlagen wird ein Vermögensaufbau zurzeit
          schwierig. Die Rendite dieser Produkte ist aktuell sehr niedrig, da
          wir uns in einer sogenannte Niedrigzinsphase befinden.
        </p>
        <h3>Welche Nachteile bringt die aktuelle Situation</h3>
        <p>
          Heute liegt die Inflationsrate über den Zinserträgen klassischer
          Sparanlagen wie dem Sparbuch. Steigt die Inflationsrate,
          verschlechtert sich das Verhältnis weiter.{" "}
          <strong>
            Dein Sparvermögen verliert an Wert, denn die Inflation (Teuerung)
            wirkt sich auf die Preise aus
          </strong>
          .
        </p>
        <h3>Wie Du trotzdem Vermögen aufbaust</h3>
        <p>
          Damit Du allerdings das Beste aus Deinem Geld machen kannst, bieten
          wir Dir eine kostenfreie Anlageberatung an.{" "}
          <strong>
            Wir erstellen gemeinsam mit Dir ein echtes Anlageprofil und
            evaluieren mit Dir welche Anlageformen zu Dir passen.
          </strong>
        </p>
      </ContentTextbox>
    ),
    three: (
      <ContentTextbox title="Wie kannst Du Vermögen aufbauen?">
        <p>
          Grundsätzlich hängt Dein Anlageerfolg auch von der Anlagedauer ab. Wir
          unterscheiden immer zuerst nach Anlagehorizont und empfehlen Dir
          entsprechende Möglichkeiten.
        </p>
        <div className="overflow-auto">
          <table className="table-auto">
            <thead>
              <tr>
                <th>Anlagedauer</th>
                <th>Anlageform</th>
                <th>Rendite</th>
                <th>Sicherheit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kurzfristig</td>
                <td>
                  Zum Beispiel: Girokonto, Tagesgeld/Festgeld und
                  Geldmarkt(nahe) Fonds
                </td>
                <td>-</td>
                <td>++</td>
              </tr>
              <tr>
                <td>Mittelfristig</td>
                <td>
                  Zum Beispiel: Defensive und ausgewogene Mischfonds,
                  Rentenfonds und offene Immobilienfonds
                </td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td>Langfristig</td>
                <td>Zum Beispiel: Offensive Mischfonds und Aktienfonds</td>
                <td>++</td>
                <td>--</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ContentTextbox>
    ),
    four: (
      <ContentTextbox title="Investmentfonds: Rendite und Sicherheit Hand in Hand">
        <p>
          Die beste Lösung damit Dein Geld sicher angelegt ist und trotzdem
          Rendite erzielt lautet Investmentfonds. Egal was für ein persönliches
          Ziel Du hast.{" "}
          <strong>
            Die kurz-, mittel- oder langfristige Ergänzung durch Investmentfonds
            kann trotz der bestehenden Risiken nahezu zu jedem Anlageziel
            passen. Der Vorteil: Du investierst nicht in Einzelwerte, sondern
            kannst je nach Auswahl vom klugen Mix im Portfolio profitieren.
          </strong>
        </p>
        <h3>So funktioniert ein Investmentfonds:</h3>
        <figure role="group" className="mb-8">
          <img src={Fonds} alt="Grafik zu Fonds" className="block w-full" />
          <figcaption>
            Quelle: BVI Bundesverband Investment, zuletzt abgerufen am
            05.04.2018.
          </figcaption>
        </figure>

        <h3>100 € Investition im Monat</h3>
        <p>
          Was beispielsweise aus einer monatlichen Investition in Höhe von 100 €
          rückblickend erwirtschaftet wurde, zeigt folgender Vergleich.
        </p>
        <div className="overflow-auto">
          <table className="table-auto overflow-auto">
            <thead style={{ borderBottom: "none" }}>
              <tr>
                <th></th>
                <th>Sparbuch</th>
                <th>Festgeld</th>
                <th>Rentenfonds</th>
                <th>Aktienfonds</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Wertzuwachs</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th>nach 20 Jahren</th>
                <td>+1.100 €</td>
                <td>+2.045 €</td>
                <td>+10.022 €</td>
                <td>+23.852 €</td>
              </tr>
              <tr className="text-gray-500">
                <th>nach 10 Jahren</th>
                <td>+83 €</td>
                <td>+135 €</td>
                <td>+1.787 €</td>
                <td>+5.565 €</td>
              </tr>
              <tr style={{ borderBottom: "none" }}>
                <td></td>
                <td className="text-center">
                  <img
                    src={Euro}
                    alt="Grafik zu Fonds"
                    className="inline w-5"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={Euro}
                    alt="Grafik zu Fonds"
                    className="inline w-6"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={Euro}
                    alt="Grafik zu Fonds"
                    className="inline w-7"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={Euro}
                    alt="Grafik zu Fonds"
                    className="inline w-8"
                  />
                </td>
              </tr>
              <tr>
                <th>Wertentwicklung</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th>nach 20 Jahren</th>
                <td>+0,44 % p.a.</td>
                <td>+0,81 % p.a.</td>
                <td>+3,35 % p.a.</td>
                <td>+6,43 % p.a.</td>
              </tr>
              <tr className="text-gray-500">
                <th>nach 10 Jahren</th>
                <td>+0,14 % p.a.</td>
                <td>+0,22 % p.a.</td>
                <td>+2,73 % p.a.</td>
                <td>+7,39 % p.a.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="text-sm text-gray-500">
          Quelle: FINANZEN FundAnalyzers (FVBS): Zeitraum 01.01.1998 bzw.
          01.01.2008 bis 31.12.2017; Basis dargestellter Werte: Aktienfonds
          „Kategorie Durchschnitt Aktienfonds Deutschland“, Rentenfonds
          „Kategorie Durchschnitt Rentenfonds EUR/EUR hedged“, Festgeld „bis
          50.000 €“, Sparbuch „gesetzliche Kündigungsfrist“. Wertentwicklung zu
          Aktien- und Rentenfonds nach BVI Methode, d. h., fondsinterne Kosten
          sind berücksichtigt. Nicht berücksichtigt wurden Depotgebühren und
          Ausgabeaufschläge von bis zu 6 % des Anlagebetrags. Wertentwicklungen
          der Vergangenheit erlauben keine Prognose von zukünftigen
          Wertentwicklungen.
        </p>
      </ContentTextbox>
    ),
    five: (
      <ContentTextbox title="Kursschwankungen an der Börse">
        <p>
          Die an den Börsen üblichen Kursschwankungen können sich je nach
          Auswahl durch die ausgewogene Struktur von Investmentfonds gut
          ausbalancieren lassen:{" "}
          <strong>
            Diversifikation ist das Zauberwort für deine Vermögensbildung.
          </strong>{" "}
          Deshalb setzen wir schon immer auf die Wertentwicklung von
          internationalen Aktienfonds.
        </p>
        <p>
          Welche der 300 von uns ausgewählten Fonds optimal zu Deinem konkreten
          Anlageziel passen, sehen wir uns in der Investmentberatung gemeinsam
          an. Eine adäquate Anlageform finden wir bestimmt –{" "}
          <strong>
            egal, ob Du 25 €, 100 € oder 300 € im Monat investieren willst.
          </strong>{" "}
          Die Summe bestimmst Du.
        </p>
      </ContentTextbox>
    ),
    fazit: (
      <ContentTextbox title="Diese Vorteile bieten wir">
        <h3>
          Maßkonfektionierte Beratung – Dein individueller Anlagevorschlag
        </h3>
        <p>
          <strong>
            Wir bieten Dir eine individuell auf dich abgestimmte Anlage für den
            Vermögensaufbau. Wir beachten Dein Anlegerprofil, Dein Anlageziel
            und die aktuelle Marktsituation.
          </strong>{" "}
          Deinem Profil entsprechend filtern wir den Markt nach empfehlenswerten
          Fonds und stellen Dir ein hochwertiges Investmentdepot zusammen.
        </p>
        <h3>Dein lebensbegleitendes Finanzkonzept</h3>
        <p>
          <strong>
            Die Betreuung Deines ganzheitlichen Finanzkonzeptes verstehen wir
            als eine Partnerschaft
          </strong>
          , auf welche Du jederzeit zurückgreifen kannst. Du erhältst auf
          Anfrage zu jeder Zeit von uns ein Reporting über die Entwicklung
          Deiner Investmentanlagen. Zudem kannst Du mit uns jederzeit die
          aktuelle Entwicklung Deiner bestehenden Investmentfonds prüfen. Sofern
          sich Deine Lebenssituation ändert, kannst du Dich vertrauensvoll an
          uns wenden, damit wir Deine Finanzplanung aktualisieren und Du
          weiterhin nachhaltig von der Finanzoptimierung profitieren kannst.{" "}
        </p>
      </ContentTextbox>
    ),
  }

  return (
    <Layout>
      <Seo
        title="Geldanlage: Wie Du Dein Geld langfristig vermehrst"
        description="Vermögensaufbau ist komplex, aber durch eine intelligente Anlagestrategie steuerbar. Wichtig ist der Überblick über die Bandbreite der Möglichkeiten."
        path="/geldanlage/allgemeine-geldanlage/"
      />
      <Header
        link={anfrageLink}
        linktext="Jetzt Anlagestrategie finden"
        image={data.image.childImageSharp.fluid}
      >
        <div className="text-sm font-semibold tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Geldanlage
        </div>
        <h1 className="mt-1 text-2xl leading-relaxed sm:text-3xl lg:text-4xl font-extrabold text-gray-900  ">
          Geldanlage:{" "}
          <span className="text-blue-500">
            Wie Du Dein Geld langfristig vermehrst
          </span>
        </h1>

        <div className="my-8 inline-flex ">
          <Link to={anfrageLink}>
            <SvgFeature svg={svg.invest} title="Vermehre Dein Geld" />
          </Link>
        </div>

        <p className="text-lg leading-7 text-gray-700">
          Vermögensaufbau ist komplex, aber durch eine intelligente
          Anlagestrategie steuerbar. Wichtig ist der Überblick über die
          Bandbreite der Möglichkeiten. Wir beraten Dich und zeigen Dir die
          große Vielfalt an Möglichkeiten auf - ganz individuell nach Deinen
          Bedürfnissen.
        </p>
      </Header>

      <FeatureSection title="Was uns besonders macht">
        <SvgFeature
          svg={svg.check}
          title="Absolut ehrlich, fair und transparente Beratung"
          text="Ehrlichkeit und eine langfristige Zusammenarbeit ist uns wichtig."
        />
        <SvgFeature
          svg={svg.check}
          title="Individuell nach Deinen Bedürfnissen"
          text="Jeder Mensch ist individuell, so auch seine Vorstellung bei der Geldanlage. Wir gehen voll und ganz auf Dich ein. "
        />
        <SvgFeature
          svg={svg.check}
          title="Ungebunden und unabhängig"
          text="Wir sind ungebunden und können Dir ein breites Spektrum an Lösungen anbieten."
        />
        <SvgFeature
          svg={svg.check}
          title="Ungebunden und unabhängig"
          text="Wir sind an keine Gesellschaft gebunden und planen so ein individuelles Altersvorsorgekonzept auf deine Wünsche zugeschnitten."
        />
        <SvgFeature
          svg={svg.check}
          title="Kostenfreie Beratung"
          text="Wir beraten Dich natürlich kostenlos."
        />
      </FeatureSection>

      <ContContentTwoColumns col1={content.one} col2={content.two} />

      <CtaSection
        title1="Vermehre Dein Geld"
        text="Geldanlage: Wie Du Dein Geld langfristig vermehrst."
        link1={anfrageLink}
        link1text="Jetzt Anlagestrategie finden"
      />
      <ContContentTwoColumns col1={content.three} col2={content.four} />
      <ContContentTwoColumns col1={content.five} col2={content.fazit} />
    </Layout>
  )
}
